import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { Transition, UIRouter, UIRouterModule } from '@uirouter/angular';
import { VbrickPlayerModule } from '@vbrick/vbrick-player';

import { AngularAppBootstrapDepsModule } from 'rev-shared/bootstrap/AngularAppBootstrapDeps.Module';
import { AppBootstrapService } from 'rev-shared/bootstrap/AppBootstrap.Service';
import { EntryPointAngularModule } from 'rev-shared/entryPoint/EntryPointAngular.Module';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { PORTAL_ADMIN_STATE } from 'rev-shared/bootstrap/AppStateNames';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { CALENDAR_STATE_NAME } from 'rev-portal/scheduledEvents/webcast/Constants';
import { DownloadDetailsModule } from 'rev-portal/downloads/DownloadDetails.Module';
import { HelpModule } from 'rev-portal/navigation/help/Help.Module';
import { MainHeaderAngularModule } from 'rev-portal/navigation/mainHeader/MainHeader.AngularModule';
import { UserProfileModule } from 'rev-portal/userProfile/UserProfile.Module';

//modules enabled by rev distributon
import { AdminLoaderModule } from 'rev-portal/admin/AdminLoader.Module';
import { ScheduledEventsLoaderModule } from 'rev-portal/scheduledEvents/ScheduledEventsLoader.Module';

import { states } from 'rev-portal/PortalStatesV2.Config';
import { PortalAppComponent } from 'rev-portal/PortalApp.Component';

export const distStates: IVbNg2StateDeclaration[] = [
	{
		name: 'portal.revDistribution',
		url: '/',
		authorizationKey: 'distribution.authenticated',
		redirectTo(transition: Transition) {
			const injector = transition.injector();
			const UserContext = injector.get<UserContextService>(UserContextService);
			const SecurityContext = injector.get<SecurityContextService>(SecurityContextService);
			const account = UserContext.getAccount();

			return SecurityContext.initializationPromise
				.then(() => {
					const isAdmin = SecurityContext.checkAuthorizations(['admin', 'events.edit', 'events.reports']);
					if (isAdmin) {
						return {
							state: PORTAL_ADMIN_STATE,
							params: { accountId: account.id }
						};
					}
					return { state: CALENDAR_STATE_NAME };
				});
		}
	},
	...states
];

function configureUiRouter(uiRouter: UIRouter): void {
	uiRouter.urlService.rules.otherwise('/');
}

@NgModule({
	bootstrap: [PortalAppComponent],
	imports: [
		AdminLoaderModule,
		AngularAppBootstrapDepsModule,
		DownloadDetailsModule,
		EntryPointAngularModule,
		HelpModule,
		MainHeaderAngularModule,
		UIRouterModule.forRoot({ config: configureUiRouter, states: distStates, useHash: true, initial: { state: 'portal.revDistribution' } }),
		ScheduledEventsLoaderModule,
		VbrickPlayerModule,
		UserProfileModule
	],
	providers: [
		{
			provide: APP_BOOTSTRAP_LISTENER,
			deps: [
				AppBootstrapService,
				UserContextService
			],
			useFactory: initRevDistribution,
			multi: true
		}
	]
})
export class RevDistributionAngularModule {}

function initRevDistribution(
	appBootstrap: AppBootstrapService,
	userContext: UserContextService
) {
	return () => {
		const account = userContext.getAccount();
		appBootstrap.setAppConfig({ isRevDistribution: true, defaultLogoUriKey: account.isUniversalEcdn ? 'ecdnUrl' : 'url' });
		userContext.logoutOnInvalidAuthKey = true;
	};
}
